<template>
  <div class="container contract commonWhiteBg">
    <h3>《知链电子书挂卖合约协议》</h3>
    <div class="subTime">最近更新日期：2019年1月23日 生效日期：2019年1月30日</div>
    <div>
      本协议是您（包括自然人、法人或其他组织，以下也称"服务使用人"）与知链科技有限公司（以下简称”知链”）之间关于知链电子书挂卖合约提供各项服务的协议。<br>

      我们在此特别提醒，在使用知链平台上提供的各项服务前，请您务必事先认真阅读本协议所有内容，协议中包括了免除或者限制知链责任的免责条款及对用户的权利限制。请您审阅并表示接受或不接受所有服务条款，若您承诺接受则须遵守本协议约定；若您不接受则应立即停止注册程序或停止使用知链提供的服务。若您有不明白或不理解之处，务必请向知链客服垂询，由客服人员向您作详细的说明和解释。<br>

      知链有权根据需要不时地制订、修改本协议及各种规范、规则等，并在知链平台上进行公告。变更后的协议和规范、规则一经公告，立即自动生效，您可以随时登录知链平台查阅相关信息。如果您不同意相关变更，应当立即停止使用知链服务。如您继续使用知链服务的，即视为您接受经修订的协议和规范、规则。<br>
    </div>
    <h4>一、知链电子书合约挂卖保护条款</h4>
    <div>
      1.1 知链所提供的服务涉及的文字表述及组合、图片、网页设计等，以及各软件、技术均属可信大数据所有，受《中华人民共和国著作权法》等法律法规、国际著作权条约、国际版权条约和其他知识产权法及条约的保护。<br>

      1.2 您应正当使用知链的各项服务，并承担一定的意外风险。在使用知链服务过程中，您不得从事任何影响网络正常运作的行为，包括借助装置、软件或例行程序进行干预等。知链在任何情况下不对因服务使用人不正当使用、有意或恶意利用服务的缺陷或其他特殊的、意外的原因（或行为）造成的非直接或间接的损失承担赔偿责任。<br>

      1.3 由于知链的各项服务涉及到互联网服务、本地操作环境、网络各环节等因素的影响，因此服务可能因计算机病毒或黑客攻击、网络提供商提供的服务差异化或出现意外状况、用户所在位置以及其他任何技术、互联网络、通信线路等原因出现异常或其他不能满足用户要求的风险。对非中国知链造成的以上风险，可信大数据不承担任何责任。<br>
    </div>
    <h4>二、知链电子书合约挂卖使用规范</h4>
    <div>
      2.1 您必须合法地使用知链的各项服务，保证在使用知链的各项服务时所作陈述、记录等内容均真实、合法，您应当对通过您的帐户所实施的一切行为负责。<br>

      2.2 您不得利用知链的各项服务及产品从事非法活动，包括且不限于利用本站传播、存取非法、违反公序良俗的内容，不可采用网络、电脑技术等各种手段，制造虚假证据，并利用知链存证，以获取个人利益、骗取保全证书。<br>

      2.3 经发现或接到举报，或有合理理由怀疑您的帐户存在违法情况及其他违反本协议的行为时，知链有权对你的帐户采取限制措施，并限期要求您向知链公开可疑内容，限期进行整改，直至删除您的资料、应公安机关、司法机关的要求公开（公示）您的信息及违法内容、注销您的帐户。知链有权对您是否存在上述违法情况、违反本协议的行为单方作出认定，采取上述各项措施，无须征得您的同意或提前通知予您。<br>

      2.4 对于您的行为（包括非法的或正常使用知链服务的行为）对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保免于对知链造成损失或增加费用。<br>

      2.5 一旦发生意外，非知链原因，收取的费用不予退还。在开始使用知链服务后，收费即开始。收费与使用功能服务的效果无关，您不得以效果不好达不到目的等理由，向知链提出退费要求。<br>

      2.6 知链提供的各项服务均为自助型服务，请勿向客服人员提出参与的要求。
    </div>
    <h4>三、知链电子书合约挂卖费用规则</h4>
    <div>
      3.1 用户在合约挂卖时设定售卖金额，售卖成功后，其中99%将直接进入到用户的钱包，剩余1%作为平台手续费。<br>
      3.2 挂卖所得收益可即时提现。
    </div>
  </div>
</template>

<script>
export default {
  name: 'contract',

  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
.contract {
  padding: 48px 64px;
  line-height: 2em;
  h3 {
    font-size: 24px;
    text-align: center;
  }
  .subTime {
    color: rgba(0, 18, 22, 0.65);
    margin: 16px 0 32px;
    text-align: center;
    font-size: 14px;
  }
  h4 {
    margin-top: 16px;
  }
  div {
      margin-top:8px;
      font-size: 14px;
      line-height: 34px;
    p {
      padding-left: 16px;
    }
  }
}
</style>
